import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useIsMutating } from '@tanstack/react-query';

import * as Filtrator from '@Stores/Filtrator';
import * as ModalWindows from '@Stores/ModalWindows';
import useModalWindowTranslation from '@Queries/useModalWindowTranslation';
import { useDeps } from '@Contexts/DI';
import useNavigation from '@Navigation/useNavigation';
import Button from '@UI/Button';
import Link from '@Navigation/Link';
import styles from './Footer.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { FilterView } from '@Types/Filters';

export interface FooterProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onReset'> {
  className?: string;
  entityId: 'catalog' | 'textile' | 'constructor' | 'configurator-textile';
  selectedFilterId?: string;
  filterView?: FilterView;
  isOnProductPage?: boolean;
  onApply?: (e: MouseEvent) => void;
  onReset?: (e: MouseEvent) => void;
}

const entityTitlesKey = {
  constructor: 'plural-fabrics',
  textile: 'plural-samples',
  ['configurator-textile']: 'plural-samples',
  catalog: 'plural-products',
};

const Footer: FC<FooterProps> = (props) => {
  const {
    entityId,
    selectedFilterId,
    filterView,
    isOnProductPage,
    onApply,
    onReset,
    ...restProps
  } = props;
  const { pathname } = useNavigation();
  const [waiting, setWaiting] = useState(false);
  const filtrator = Filtrator.useFiltrator(entityId);
  const resetLink = Filtrator.useResetLink(entityId);
  const totalCount = Filtrator.useTotalCount(entityId);
  const { t } = useModalWindowTranslation({ modalId: 'Filters' });
  const url = Filtrator.toUrl(entityId);
  const resetAllUrl = `${pathname}${resetLink}`;
  const isMutatingProductsCount = !!useIsMutating({ mutationKey: ['select-filter'] });
  const isMutatingCount = !!useIsMutating({ mutationKey: ['selected-filters'] });
  const { analytics } = useDeps();
  const isViewSticky = filterView === 'sticky';

  // Ссылка для применения выбранных фильтров
  const applyUrl = useMemo(() => {
    const chunks = pathname.split('/');

    // Генерируем ссылку без номера текущей страницы, так как после фильтрации
    // пользователь должен попадать на первую страницу
    const pathnameWithoutPage = chunks
      .filter((chunk) => {
        const isPage = chunk.match(/page-[0-9]{1,}/);

        return !isPage;
      })
      .join('/');

    return `${pathnameWithoutPage}${url}`;
  }, [pathname, url]);

  const totalCountText = useMemo(() => {
    if (typeof totalCount !== 'number') return;
    if (totalCount === null || totalCount < 0) return null;

    return `(${totalCount} ${t(entityTitlesKey[entityId], totalCount)})`;
  }, [entityId, totalCount, t]);

  const handleApply = useCallback(
    (e: MouseEvent) => {
      setWaiting(true);

      if (filtrator.withoutUrl) {
        Filtrator.applyFilters(entityId);
        ModalWindows.close('Filters');
      }

      if (onApply) onApply(e);

      const allAppliedFilters = Filtrator.getAppliedFiltersNames(entityId);

      analytics.dispatchEvent('filters.apply', {
        allAppliedFilters,
        label: selectedFilterId === 'all' ? 'Все фильтры' : selectedFilterId,
        status: isViewSticky ? 'закреп' : 'не закреп',
        isOnProductPage,
      });

      setWaiting(false);
    },
    [
      filtrator.withoutUrl,
      onApply,
      entityId,
      analytics,
      selectedFilterId,
      isViewSticky,
      isOnProductPage,
    ],
  );

  const handleReset = useCallback(
    (e: MouseEvent) => {
      setWaiting(true);
      const allAppliedFilters = Filtrator.getAppliedFiltersNames(entityId);

      Filtrator.resetAll(entityId);

      analytics.dispatchEvent('filters.clear', {
        allAppliedFilters,
        label: selectedFilterId === 'all' ? 'Все фильтры' : selectedFilterId,
        status: isViewSticky ? 'закреп' : 'не закреп',
        isOnProductPage,
      });

      if (onApply) onApply(e);
      if (onReset) onReset(e);

      setWaiting(false);
    },
    [entityId, analytics, selectedFilterId, isViewSticky, onApply, onReset, isOnProductPage],
  );

  /**Show loading status if request lasts more than 1.5 seconds  */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setWaiting(isMutatingProductsCount || isMutatingCount);
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isMutatingProductsCount, isMutatingCount]);

  return (
    <div {...restProps}>
      <Button
        wide
        waiting={waiting}
        to={filtrator.withoutUrl ? undefined : applyUrl}
        onClick={handleApply}
      >
        <span className={styles.textApply}>{t('apply')}</span>
        {totalCountText && <span className={styles.textTotalCount}>{totalCountText}</span>}
      </Button>
      <div className={styles.footerAdditional}>
        <Link
          className={styles.reset}
          view='secondary'
          underlined
          to={filtrator.withoutUrl ? undefined : resetAllUrl}
          preventDefault={filtrator.withoutUrl}
          onClick={handleReset}
        >
          {t('clear')}
        </Link>
      </div>
    </div>
  );
};

export default memo(Footer);
